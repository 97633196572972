import { useEffect, useState } from 'react';
import { copyTextToClipboard } from '../../../helpers/utils';

const useCopyClipboard = (afterCopy) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  const onCopyText = (text) => {
    copyTextToClipboard(text).then(
      () => {
        setCopied(true);
        if (afterCopy) {
          afterCopy();
        }
      },
      () => {
        setCopied(false);
      },
    );
  };

  return [isCopied, onCopyText];
};

export default useCopyClipboard;
